<template>
  <div class="container-fliud">
    <Header></Header>
<div class="faqInfo">

  <h4 class="faq">Regster</h4>
   <hr>
<div class="gray">
   
        <div class="row" id="tweets">
            <form @submit.prevent="Register">
                <div class="heading">
                    <h1 style="margin-right:40px">Register</h1>
                    <h6>Already have an account? <router-link to='/login' class="login">Sign in</router-link></h6>
                </div> <div class="alert alert-danger" id="error" style="display:none" role="alert" v-if="errors">
                    {{errors}}
                </div>
                
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="fname">First Name</label>
                            <input type="text" class="form-control" v-model="fname" id="fname" aria-describedby="firstname" placeholder="Enter your name">
                            <span v-if="msg.fname" style="color:red">{{msg.fname}}</span>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="fname">Last Name</label>
                            <input type="text" class="form-control" v-model="lname" id="lname" aria-describedby="lasttname" placeholder="Enter your name">
                            <span v-if="msg.lname" style="color:red">{{msg.lname}}</span>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="email">Email address</label>
                    <input type="email" class="form-control" v-model="email" id="email" aria-describedby="emailHelp" placeholder="Enter your  email address">
                    <span v-if="msg.email" style="color:red">{{msg.email}}</span>
                    
                </div>
                <div class="form-group">
                    <label for="passworld">Password</label>
                    <input type="password" class="form-control" v-model="password" id="password" placeholder="Password">
                    <span v-if="msg.password" style="color:red">{{msg.password}}</span>
                </div>
                <div class="form-group">
                    <div class="g-recaptcha" data-sitekey="6LcN0AobAAAAADr6drJ5Y2DzDwprPOiJmuXh8Olp"></div>
                </div>
                <button type="submit" class="btn btn-primary form-control">Register  <span class="spinner-border text-light" id="spinner" role="status" style="display:none; width:20px; height:20px; margin-left:20px">
                    </span>
                    
                </button>
            </form>
        </div>
</div>

</div>
    <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import baseUrl from '../baseUrl';
import Header from '../components/Navbar';
import Footer from '../components/footer';
import $ from 'jquery'


export default {
  name: 'Home',
  components: {
    Header,
   Footer
  },
  data(){
      return{
          fname:'',
          lname:'',
          email:'',
          password:'',
          msg:[],
          errors:[]
      }
  },
  watch:{
       fname(value){
        this.fname = value;
        this.validateFname(value);
        },
       lname(value){
        this.lname = value;
        this.validateLname(value);
        },
      email(value){
          this.email = value;
          this.validateEmail(value)
      },
       password(value){
        this.password = value;
        this.validatePassword(value);
    }
  },
  methods: {
       validateFname(value){
            if (value.length<3)
                {
                    this.msg['fname'] = 'Enter your first name';
                    return false
                }
                 else{
                     this.msg['fname'] = '';
                } 
            },
            validateLname(value){
            if (value.length<3)
                {
                   this.msg['lname'] = 'Enter your last name';
                   return false
                } else{
                      this.msg['lname'] = '';
                } 
            },
       validateEmail(value){
           const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (re.test(value))
                {
                    this.msg['email'] = '';
                } else{
                    this.msg['email'] = 'Invalid Email Address';
                    return false
                } 
            },
            validatePassword(value){
            const passwordReg =/^(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
            if (passwordReg.test(value)) {
                 this.msg['password'] = '';
            } else {
                this.msg['password'] = 'Password not strong enough' ;
                return false
            }
            },
      Register(){
          const emailRegex= /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.email);
           
           const passwordReg =/^(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(this.password);

          if(this.fname == ""){
              this.msg['fname']="Enter first name";
              return false
          }else if(this.fname.length<3){
              this.msg['fname']="Invalid first name";
              return false
          }else{
              this.msg['fname']=""
          }
          //last name validation
          if(this.lname ==""){
              this.msg['lname']="Enter last name";
              return false
          }else if(this.lname.length<3){
              this.msg['lname']="Invalid last name";
              return false
          }else{
              this.msg['lname']=""
          }
          //email
           if(this.email ==""){
              this.msg['email']="Enter email address";
              return false
          }else if(!emailRegex){
              this.msg['email']="Invalid email address";
              return false
          }else{
              this.msg['email']=""
          }
          //password
           if(this.password ==""){
              this.msg['password']="Enter password";
              return false
          }else if(!passwordReg){
              this.msg['password'] = 'Password not strong enough' ;
                return false
          }
          else{
              this.msg['password']=""
          }
            $('#spinner').show();
          baseUrl.post('/api/user/signup',{
              fname:this.fname,
              lname:this.lname,
              email:this.email,
              password:this.password
          })
          .then((res)=>{
                $('#spinner').hide();
              console.log(res)
              this.fname = "",
              this.lname="",
              this.email="",
              this.password = ""
              this.$router.push('/')
          })
          .catch((err)=>{
                $('#spinner').hide();
                if(status=="03"){
                     $("#error").show()
                      console.log(err)
                        this.errors = "Account already registered"
                        this.fname = "",
                        this.lname="",
                        this.email="",
                        this.password = ""
                }else{
                     $("#error").show()
                    this.errors = err.data.message
                     this.fname = "",
                        this.lname="",
                        this.email="",
                        this.password = ""
                }
            
          })
      }
  },
}
</script>
<style scoped>
.faqInfo{
margin-top: 200px;
}
.gray{
background-color: rgb(255, 255, 255);
padding-bottom: 50px;
display:flex;
justify-content: center;
align-items: center;
}
form{
    border: 1px solid rgb(168, 165, 165);
    padding: 50px;
}
.heading{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 35px;
}
.faq{
padding-left: 100px;
}
.btn{
    background-color: #97c149;
    border: #97c149;
}
.login{
    color: #97c149;
}

</style>